import { createRouter, createWebHistory } from "vue-router";
import CallPage from "./components/CallPageComponents.vue";
import Home from "./components/HomeComponent.vue";

// 路由設定
const routes = [
  {
    path: '/:tenant',
    name: 'home',
    component: Home
  },
  {
    path: '/:tenant/callPage',
    name: 'callPage',
    component: CallPage
  },
];

// 創建 router 實例
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
