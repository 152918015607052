<template>
    <audio ref="audioPlayer" :src="audioSrc" type="audio/mp3" loop />
    <audio ref="laterPlayer" :src="audioLaterSrc" type="audio/mp3" loop />
    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100vh;">
        <img :src="require('@/assets/tiktok-seeklogo.png')" class="responsive-image" alt="支付圖片"
            style="width: 150px; margin-bottom: 20px;">
        <input type="text" id="realname" v-model="nameInput" placeholder="请输入真实姓名" style="width: 85%; max-width: 400px;height: 25px;">
        <input type="tel" id="phone" v-model="phoneInput" placeholder="请输入手机号"  style="width: 85%; max-width: 400px; margin-top: 10px; height: 25px;">
        <button class="btn btn-call" @click="callCaer()">拨打客服电话</button>
        <button class="btn btn-end">結束通話</button>
        <button class="btn btn-download">下载抖音会议</button>
        <p class="working-time">会员客服值班时间：8:00-20:00</p>
    </div>
</template>

<script>
import Peer from 'peerjs';
import { v4 as uuidv4 } from 'uuid'
import * as signalR from "@microsoft/signalr";
export default {
    data() {
        return {
            peer: null,
            peerId: '',
            audioSrc: require('@/assets/music.mp3'), // 引入 MP3 音樂
            audioLaterSrc: require('@/assets/Later.mp3'), // 引入 MP3 音樂
            localStream: null,
            connectionSignalR: null,
            waiteCount: 0,
            phoneInput: '',
            nameInput: '',
            call: null,
        };
    },
    async mounted() {
        this.initPeer();

    },

    methods: {
        initPeer() {
            console.log(process.env.VUE_APP_PEERJS_HOST);
            const storedPeerId = localStorage.getItem('peerId'); // 從 localStorage 中獲取 Peer ID

            if (storedPeerId !== null) {
                this.peerId = storedPeerId;
            } else {
                this.peerId = uuidv4();
                localStorage.setItem('peerId', this.peerId);
            }
            console.log('我的 Peer ID:', this.peerId);
            this.peer = new Peer(this.peerId, {
                host: process.env.VUE_APP_PEERJS_HOST,
                secure: process.env.VUE_APP_PEERJS_SECURE === 'true',
                key: process.env.VUE_APP_PEERJS_KEY,
                path: process.env.VUE_APP_PEERJS_PATH,
                config: {
                    iceServers: [{
                        urls: 'turn:47.242.207.96:3478',
                        username: 'logic',
                        credential: '@Ss5566123'
                    }],
                    iceTransportPolicy: 'relay' // 僅使用 TURN 中繼
                }
            });

            // 監聽來電
            this.peer.on('call', (call) => {
                console.log('接收到來電');
                this.call = call;

                // 獲取本地音訊流
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then((stream) => {
                        this.localStream = stream; // 儲存本地流以便管理
                        this.call.answer(stream); // 接聽來電

                        // 處理遠端音訊流
                        this.call.on('stream', (remoteStream) => {
                            if (remoteStream) {
                                const audio = new Audio();
                                audio.srcObject = remoteStream;
                                audio.play()
                                    .then(() => console.log("遠端音訊播放成功"))
                                    .catch(err => {
                                        console.error('播放遠端音訊時出錯:', err);
                                    });
                            } else {
                                console.error('未接收到有效的遠端音訊流');
                            }
                        });

                        // 處理通話錯誤
                        this.call.on('error', (err) => {
                            console.error('通話錯誤:', err);
                        });

                        // 通話結束事件
                        this.call.on('close', () => {
                            console.log('通話已結束');
                            // 停止本地音訊流
                            if (this.localStream) {
                                this.localStream.getTracks().forEach((track) => track.stop());
                                this.localStream = null; // 清空本地流
                            }
                            this.call = null; // 清空通話對象
                        });
                    })
                    .catch((err) => {
                        console.error('獲取媒體失敗:', err);
                    });
            });

        },

        async reSet(audio) {
            try {
                await audio.pause()
                audio.currentTime = 0;
            } catch (err) {
                console.error('audio reSet error:', err)
            }
        },

        async autoPlay(audio) {
            await audio.play()
                .then(() => {
                    console.log(audio.src + ' 音樂撥放成功');
                }).catch((err) => {
                    console.error(audio.src + ' 音樂撥放失敗:', err);
                });
        },
        async callClose() {
            console.log('通話結束')
            this.call.close();
        },

        async callCaer() {
            // 循環播放音樂
            // 初始化並連接到 SignalR Hub
            this.connectionSignalR = new signalR.HubConnectionBuilder()
                .withUrl(`${process.env.VUE_APP_SIGNAL_R_URL}/chatHub`, {
                    withCredentials: false,
                })
                .build();

            try {
                // 啟動連接
                await this.connectionSignalR.start()
                    .then(async () => {
                        console.log("SignalR 連線成功");
                        this.autoPlay(this.$refs.audioPlayer)
                    })
                    .catch((err) => {
                        console.error("SignalR 連線錯誤:", err);
                        this.error = err.toString();
                    });
                // eslint-disable-next-line no-unused-vars
                // 設定接收到 "WaitAnswer" 訊息的回調
                await this.connectionSignalR.on("WaitAnswer", async () => {
                    try {
                        // 呼叫等待 10 秒的函數
                        await this.delay(12000); // 延遲 10000 毫秒 (10 秒)
                        this.waiteCount = this.waiteCount + 1;
                        await this.autoPlay(this.$refs.laterPlayer);
                        await this.reSet(this.$refs.audioPlayer);
                        await this.connectionSignalR.invoke("CallCare", localStorage.getItem("peerId"), this.nameInput, this.phoneInput)
                            .then(() => console.log(`Please wait for the answer.${this.waiteCount}`))
                            .catch((err) => console.error(`WaitAnswer CallCare.${this.waiteCount} `, err));
                    } catch (err) {
                        console.error("Error with SignalR connection or message sending:", err);
                    }

                });

                this.connectionSignalR.on("ReDial", async () => {
                    console.log("Please wait for the ReDial.");
                    await this.connectionSignalR.invoke("CallCare", localStorage.getItem("peerId"), this.nameInput, this.phoneInput)
                        .then(() => console.log(`ReDial CallCare 成功`))
                        .catch((err) => console.error('ReDial CallCare error', err));
                });

                await this.connectionSignalR.invoke("RatAuthorize", this.$route.params.tenant)
                    .then(() => console.log('身份驗證成功'))
                    .catch((err) => console.error('身份驗證 error', err));
                // 呼叫伺服器端的 CallCare 方法
                await this.connectionSignalR.invoke("CallCare", localStorage.getItem("peerId"), this.nameInput, this.phoneInput)
                    .then(() => console.log('first CallCare 成功'))
                    .catch((err) => console.error('first CallCare error', err));

                this.connectionSignalR.on('SessionOpen', async () => {
                    console.log('準備接通 ...');
                    this.reSet(this.$refs.audioPlayer);
                    this.reSet(this.$refs.laterPlayer);
                });
            } catch (err) {
                console.error("Error with SignalR connection or message sending:", err);
            }


        },

        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    },

    computed: {
        // 計算屬性判斷兩個 input 是否都有值
        isFormValid() {
            return this.phoneInput.trim() !== '' && this.nameInput.trim() !== '';
        }
    }
};
</script>

<style>
/* 外部容器設定 */
.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    /* 黑色背景 */
    padding: 20px;
    font-family: Arial, sans-serif;
    /* 字體 */
}

input:focus {
    box-shadow: 0 0 10px 2px #9e66ff;
}

/* 通用按鈕樣式 */
.btn {
    width: 90%;
    /* 按鈕寬度 */
    max-width: 400px;
    /* 最大寬度 */
    height: 35px;
    /* 按鈕高度 */
    margin: 8px 0;
    /* 按鈕間距 */
    border: none;
    border-radius: 25px;
    /* 圓角 */
    font-size: 14px;
    /* 字體大小 */
    font-weight: bold;
    /* 字體粗體 */
    color: white;
    /* 字體顏色 */
    cursor: pointer;
    text-align: center;
}

/* 按鈕顏色 */
.btn-call {
    background-color: #007aff;
    /* 藍色 */
}

.btn-end {
    background-color: #ff3b30;
    /* 紅色 */
}

.btn-download {
    background-color: #8e8e93;
    /* 灰色 */
}

/* 值班時間文字 */
.working-time {
    color: white;
    /* 白色文字 */
    font-size: 12px;
    /* 字體大小 */
    margin-top: 20px;
    text-align: center;
}
</style>