<template>
    <div>
        <img src="@/assets/pay.jpg" class="responsive-image">
    </div>
    <div>
        <img src="@/assets/iss1.png" alt="iss1">
        <img src="@/assets/iss2.png" alt="iss2">
        <img src="@/assets/iss3.png" alt="iss3">
    </div>
    <h6>合作保险公司</h6>
    <div class="container">
        <div class="container">
            <img src="@/assets/Insurance1.png" class="image-container" alt="保險公司1">
            <img src="@/assets/Insurance2.png" class="image-container" alt="保險公司2">
            <img src="@/assets/Insurance3.png" class="image-container" alt="保險公司3">
            <img src="@/assets/Insurance4.png" class="image-container" alt="保險公司4">
            <img src="@/assets/Insurance5.png" class="image-container" alt="保險公司5">
            <img src="@/assets/Insurance6.png" class="image-container" alt="保險公司6">
            <img src="@/assets/Insurance7.png" class="image-container" alt="保險公司7">
            <img src="@/assets/Insurance8.png" class="image-container" alt="保險公司8">
            <img src="@/assets/Insurance9.png" class="image-container" alt="保險公司9">
            <img src="@/assets/Insurance10.png" class="image-container" alt="保險公司10">
            <img src="@/assets/Insurance11.png" class="image-container" alt="保險公司11">
        </div>
    </div>
    <div class="container">
        <button @click="navigateToPage()" class="floating-button">
            <img src="@/assets/—Pngtree—flat customer service_4436647.png" width="auto">
        </button>
    </div>
    <h6>抖音合作伙伴</h6>
    <div class="container">
        <div class="container">
            <img src="@/assets/TikTok1.png" class="image-container" alt="保險公司1">
            <img src="@/assets/TikTok2.png" class="image-container" alt="保險公司2">
            <img src="@/assets/TikTok3.png" class="image-container" alt="保險公司3">
            <img src="@/assets/TikTok4.png" class="image-container" alt="保險公司4">
            <img src="@/assets/TikTok5.png" class="image-container" alt="保險公司5">
            <img src="@/assets/TikTok6.png" class="image-container" alt="保險公司6">
            <img src="@/assets/TikTok7.png" class="image-container" alt="保險公司7">
            <img src="@/assets/TikTok8.png" class="image-container" alt="保險公司8">
            <img src="@/assets/TikTok9.png" class="image-container" alt="保險公司9">
            <img src="@/assets/TikTok10.png" class="image-container" alt="保險公司10">
            <img src="@/assets/TikTok11.png" class="image-container" alt="保險公司11">
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        navigateToPage() {
            this.$router.push({name:'callPage' })
        },
    },
}
</script>
<style>
.container {
    display: flex;
    /* 啟用 Flexbox */
    flex-wrap: wrap;
    /* 允許換行 */
    align-items: flex-start;
    /* 控制圖片的垂直對齊 */
    /* 控制垂直對齊 */
    justify-content: flex-start;
    /* 控制圖片的垂直對齊 */
}

.image-container {
    width: 80%;
    /* 調整圖片寬度 */
    max-width: 100px;
    /* 可選：設置容器的最大寬度 */
    height: auto;
    /* 高度自動調整 */
}

.responsive-image {
    width: 100%;
    /* 圖片寬度自適應容器 */
    height: auto;
    /* 高度根據寬度比例自動調整 */
    margin: 0;
    /* 清除上下左右的 margin */
    padding: 0;
    /* 清除上下左右的 padding */
}

html,
body {
    margin: 0;
    padding: 0;
    background-color: #000;
}

/* 定義跳動的動畫 */
@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(-10px);
        /* 上跳 */
    }

    50% {
        transform: translateY(0);
        /* 回到原位 */
    }

    70% {
        transform: translateY(-5px);
        /* 小幅度上跳 */
    }

    100% {
        transform: translateY(0);
        /* 回到原位 */
    }
}

.floating-button {
    position: fixed;
    right: 0px;
    /* 靠右 */
    top: 20%;
    /* 垂直居中 */
    transform: translateY(-50%);
    /* 調整按鈕的垂直位置，確保居中 */
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明灰色背景 */
    border: 2px solid #4CAF50;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    /* 背景顏色和邊框顏色過渡效果 */
    animation: bounce 1s ease infinite;
    /* 跳動動畫 */
}

/* 圖片樣式 */
.floating-button img {
    max-width: 30px;
    max-height: 30px;
    object-fit: contain;
}

/* 鼠標懸停效果 */
.floating-button:hover {
    background-color: rgba(128, 128, 128, 0.7);
    /* 當懸停時，灰色背景變為更不透明 */
    border-color: #388E3C;
    /* 邊框顏色變暗 */
}

/* 轉跳按鈕樣式 */
.floating-link {
    position: fixed;
    right: 20px;
    /* 靠右 */
    top: 50%;
    /* 垂直居中 */
    transform: translateY(-50%) translateX(100%);
    /* 調整轉跳按鈕的位置，使其顯示在懸浮按鈕的右側 */
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    display: none;
    /* 預設隱藏 */
    cursor: pointer;
    transition: opacity 0.3s;
}

/* 懸停時顯示轉跳按鈕 */
.floating-button:hover+.floating-link {
    display: block;
    opacity: 1;
}

.container {
    display: flex;
    /* 啟用 Flexbox */
    justify-content: space-around;
    /* 控制水平間距，可以用 space-between, space-evenly 或 flex-start 等 */
    align-items: center;
    /* 控制垂直對齊 */
}

.round-button {
    background-color: transparent;
    /* 背景透明 */
    color: #4CAF50;
    /* 圖標顏色 */
    border: 2px solid #4CAF50;
    /* 綠色邊框 */
    border-radius: 50%;
    /* 圓形 */
    width: 60px;
    /* 按鈕寬度 */
    height: 60px;
    /* 按鈕高度 */
    display: flex;
    /* 使用 Flexbox */
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
    font-size: 24px;
    /* 圖標大小 */
    cursor: pointer;
    /* 鼠標懸停變為手形 */
    transition: background-color 0.3s, border-color 0.3s;
    /* 背景顏色和邊框顏色過渡效果 */
}

/* 懸浮按鈕樣式 */
.floating-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: transparent;
    border: 2px solid #4CAF50;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

/* 圖片樣式 */
.floating-button img {
    max-width: 50px;
    max-height: 50px;
    object-fit: contain;
}

/* 懸停按鈕效果 */
.floating-button:hover {
    background-color: rgba(76, 175, 80, 0.1);
    border-color: #388E3C;
}

/* 轉跳按鈕樣式 */
.floating-link {
    position: fixed;
    right: 20px;
    bottom: 90px;
    /* 調整與浮動按鈕的距離 */
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    display: none;
    /* 預設隱藏 */
    cursor: pointer;
    transition: opacity 0.3s;
}

/* 懸停時顯示轉跳按鈕 */
.floating-button:hover+.floating-link {
    display: block;
    opacity: 1;
}

.round-button img {
    max-width: 90px;
    /* 設定圖片的最大寬度 */
    max-height: 90px;
    /* 設定圖片的最大高度 */
    object-fit: contain;
    /* 保持圖片比例 */
    display: block;
    /* 防止圖片底部有空白 */
}

/* 鼠標懸停效果 */
.round-button:hover {
    background-color: rgba(76, 175, 80, 0.1);
    /* 當懸停時背景顏色變為半透明 */
    border-color: #388E3C;
    /* 邊框顏色變暗 */
}
</style>
